import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import LeadingBrand from './pages/LeadingBrand';
import BusinessPlan from './pages/BusinessPlan';
import Partnership from './pages/Partnership';
import Resources from './pages/Resources';
import NewsHighlight from './pages/NewsHighlight';
import ProductUpdates from './pages/ProductUpdates';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import FAQ from './pages/FAQ';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import AboutUs from './pages/AboutUs';
import WhyChooseUs from './pages/WhyChooseUs';
import MicroBusinessSolutions from './pages/MicroBusinessSolutions';
import ArticleOne from './articles/ArticleOne';
import ArticleTwo from './articles/ArticleTwo';
import ArticleThree from './articles/ArticleThree';
import MicroBusinessSolutionsFeature from './pages/MicroBusinessSolutionsFeature';
import Pricing from './pages/Pricing';
import MicroBusinessSolutionsStore from './pages/MicroBusinessSolutionsStore';
import ThemeDesign from './pages/ThemeDesign';

import 'bootstrap-icons/font/bootstrap-icons.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
function App() {
  return (
    <Router>
      <div className="App">
        <Header />
       
        <div className="content-big-wrap">
          <Routes>
            <Route path="/" element={<Navigate to="/Home" />} />
            <Route path="/home" element={<Home />} />
            <Route path="/leading-brand" element={<LeadingBrand />} />
            <Route path="/business-plan" element={<BusinessPlan/>} />
            <Route path="/partner-ship" element={<Partnership/>} />
            <Route path="/resources" element={<Resources/>} />
            <Route path="/news-highlights" element={<NewsHighlight/>} />
            <Route path="/blog" element={<Blog/>} />
            <Route path="/product-updates" element={<ProductUpdates/>} />
            <Route path="/contact" element={<Contact/>} />
            <Route path="/faq" element={<FAQ/>} />
            <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
            <Route path="/terms-Conditions" element={<TermsAndConditions/>} />
            <Route path="/about-us" element={<AboutUs/>} />
            <Route path="/why-choose-us" element={<WhyChooseUs/>} />
            <Route path="/micro-business-solutions" element={<MicroBusinessSolutions/>} />
            <Route path="/article-one" element={<ArticleOne/>} />
            <Route path="/article-two" element={<ArticleTwo/>} />
            <Route path="/article-three" element={<ArticleThree/>} />
            <Route path="/micro-business-solutions-feature" element={<MicroBusinessSolutionsFeature/>} />
            <Route path="/pricing" element={<Pricing/>} />
            <Route path="/micro-business-solutions-store" element={<MicroBusinessSolutionsStore/>} />
            <Route path="/theme-design" element={< ThemeDesign/>} />
           
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
