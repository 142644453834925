import React, { useEffect } from 'react';
import '../styles/ThemeDesign.css'; 
import '../styles/style.css'; 
import BannerSection from '../components/BannerSection';
import ThemeDesignBanner from '../assets/ThemeDesignBanner.png';
import TemplateImg from '../assets/TemplateImg.png'; 

const ThemeDesign = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const themes = [
    { name: 'Dusk', image: TemplateImg, new: true },
    { name: 'Dawn', image: TemplateImg },
    { name: 'Noon', image: TemplateImg },
  ];

  return (
    <section className="theme-design">
      <div>
        <BannerSection
          category="自選版型"
          title="自由選擇具質感且專業的版面，讓您的網路門面更具吸引力"
          subtitle='立即點選預覽屬於您的專屬風格！請<a href="/contact">聯繫我們</a>'
          backgroundImage={ThemeDesignBanner}
        />
      </div>
      <div className="bg-gray">
        <div className="container">
            <div className="theme-preview py-5">
              <div className="row align-items-center">
                <div className="col-md-6 col-sm-6 text-center">
                  <img src={TemplateImg} alt="Dusk Theme" className="img-fluid mb-3 w-50" />
                </div>
                <div className="col-md-6">
                  <h3 className="theme-name">
                    {themes[0].new && <span className="badge new">NEW</span>}<br></br>
                    {themes[0].name}
                  </h3>
                  <p className="theme-description">
                    Dusk風格設計的靈感來自於大自然的優美，整體視覺優雅而富有質感。其專業的排版設計，讓內容展示更為豐富。簡約而不簡單的設計風格，適合多元產品展示，讓品牌更具吸引力。
                  </p>
                  <a href="#" className="custom-button-bottom">立即預覽 →</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
        <div className="row theme-gallery mt-5">
          {themes.map((theme, index) => (
            <div className="col-lg-4 col-md-6 mb-4" key={index}>
              <a href=''>
              <div className="theme-item">
                <img src={theme.image} alt={`${theme.name} Theme`} className="img-fluid w-50" />
                <h4 className="theme-name">{theme.name}</h4>
              </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ThemeDesign;
