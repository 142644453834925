import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import '../styles/BusinessPlan.css'; 
import BannerSection from '../components/BannerSection';
import CallToActionTwo from '../components/CallToActionTwo';
import BusinessPlanBanner from '../assets/BusinessPlanBanner.png'; 
import AdvantageImage from '../assets/advantage-image.png'; 
import Icon from '../assets/icon/list.svg'; 
import Icon1 from '../assets/icon1.png'; 
import Icon2 from '../assets/icon2.png'; 
import Icon3 from '../assets/icon3.png'; 
import Icon4 from '../assets/icon4.png'; 
import Icon5 from '../assets/icon5.png'; 

const BusinessPlan = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <section className="BusinessPlan">
      <Helmet>
        <title>異業結盟計畫 - 成為Big-O合作夥伴 | 數位轉型與成長</title>
        <meta name="description" content="加入Big-O異業合作夥伴計畫，透過協作與創新，實現企業的數位轉型，並提供客戶全方位的服務價值。" />
        <meta name="keywords" content="異業結盟, 數位轉型, 企業成長, Big-O合作夥伴, 商業發展" />
      </Helmet>

      <div>
        <BannerSection 
          category="成為Big-O異業合作夥伴"
          title="異業結盟計畫"
          subtitle="協助客戶「落實數位轉型」，建立「e鍵」即完成更多目標。"
          backgroundImage={BusinessPlanBanner}
        />
      </div>

      <section className="py-4">
        <div className="advantages-section container pt-5 pb-5">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="image-wrapper text-center">
                <img src={AdvantageImage} alt="成為異業合作夥伴的優勢" className="img-fluid w-75" />
              </div>
            </div>
            <div className="col-md-6 p-5">
              <h2 className="mb-4">成為異業合作夥伴的五大優勢</h2>
              <ul>
                <li>
                  <img src={Icon} alt="擴大您的知名度" className="icon" />
                  擴大您的知名度
                </li>
                <li>
                  <img src={Icon} alt="提供不同產業相互交流" className="icon" />
                  提供不同產業相互交流與鏈結
                </li>
                <li>
                  <img src={Icon} alt="AI科技新技術吸客" className="icon" />
                  AI科技新技術吸客
                </li>
                <li>
                  <img src={Icon} alt="協作建立Big-O商業發展生活圈" className="icon" />
                  協作建立「Big-O商業發展生活圈」
                </li>
                <li>
                  <img src={Icon} alt="提供新創產業e鍵式服務" className="icon" />
                  提供新創產業「e鍵式服務」
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="py-4 bg-gray">
        <div className="pt-4 container text-center">
          <h2 className="text-center mb-5">異業結盟計畫提供客戶的價值</h2>
          <div className="row justify-content-center text-center">
            <div className="col-md-2 col-sm-6 mb-4">
              <div className="value-item">
                <img src={Icon1} alt="擴大市場規模" className="img-fluid mb-3" />
                <p>擴大市場規模</p>
              </div>
            </div>
            <div className="col-md-2 col-sm-6 mb-4">
              <div className="value-item">
                <img src={Icon2} alt="強化產業佈局" className="img-fluid mb-3" />
                <p>強化產業佈局</p>
              </div>
            </div>
            <div className="col-md-2 col-sm-6 mb-4">
              <div className="value-item">
                <img src={Icon3} alt="加速轉型彈性" className="img-fluid mb-3" />
                <p>加速轉型彈性</p>
              </div>
            </div>
            <div className="col-md-2 col-sm-6 mb-4">
              <div className="value-item">
                <img src={Icon4} alt="敏捷式新技術" className="img-fluid mb-3" />
                <p>敏捷式新技術</p>
              </div>
            </div>
            <div className="col-md-2 col-sm-6 mb-4">
              <div className="value-item">
                <img src={Icon5} alt="落實在地服務" className="img-fluid mb-3" />
                <p>落實在地服務</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <CallToActionTwo
        title="成為Big-O異業合作夥伴"
        subtitle="透過您的產品與服務，壯大您的客戶量，並使其成長茁壯，同步提升您的產品所帶來的「綜效」。"
        buttonText="申請加入合作夥伴"
        buttonLink="/contact"
      />
    </section>
  );
};

export default BusinessPlan;
