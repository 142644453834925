import React, { useEffect } from 'react';
import '../styles/Pricing.css';
import PricingSwitcher from '../components/PricingSwitcher';
import PricingSwitcherTwo from '../components/PricingSwitcherTwo'; 
import BannerSection from '../components/BannerSection';
import PricingBanner from '../assets/pricing-banner.png';

const pricingData = {
  monthly: [
    {
      title: '專業方案',
      price: '750', 
      annualPrice: '8,999',
      description: '快速建立專業形象',
      period: '/month',
      tooltipContent: (
        <ul>
          <li>方案僅限年繳</li>
          <li>另需收取一次性系統開通設定費NT$5,000</li>
          <li>每月收取定額系統維護費5%(依專案費用5%計收)</li>
        </ul>
      ),
      features: [
        '固定套版',
        '後台管理',
        '頁面數量5頁',
        '自由編輯修改',
        '子網域',
      ],
      notIncluded: [''],
    },
    {
      title: '尊爵方案',
      price: '917',
      annualPrice: '10,999',
      description: '提升曝光機會，有效抓住客群',
      period: '/month',
      tooltipContent: (
        <ul>
          <li>方案僅限年繳</li>
          <li>另需收取一次性系統開通設定費NT$5,000</li>
          <li>每月收取定額系統維護費5%(依專案費用5%計收)</li>
        </ul>
      ),
      features: [
        '套版二選一',
        '專屬網域',
        '頁面數量5頁',
        '商品上架介紹',
        '商品上架數量12個',
      ],
    },
    {
      title: '客製化方案',
      price: '議定',
      period: '',
      description: '專為新創企業而生的網路形象代表，提供快速佈建及資訊瀏覽可與世界接軌，走向國際化',
      tooltipContent: '請聯絡我們',
      features: [
        '具備客製化及質感的介面設計',
        '專屬網域',
        '頁面數量依據客戶需求而定',
        '是否需商品上架頁及上架數量',
        '系統建置專屬服務',
        '線上系統顧問諮詢',
      ],
    },
  ],
  annual: [
    {
      title: '專業方案',
      price: '8,999',
      originalPrice: '12,499',
      period: '/year',
      description: '快速建立專業形象',
      tooltipContent: (
        <ul>
          <li>方案僅限年繳</li>
          <li>另需收取一次性系統開通設定費NT$5,000</li>
          <li>每月收取定額系統維護費5%(依專案費用5%計收)</li>
        </ul>
      ),
      features: [
        '固定套版',
        '後台管理',
        '頁面數量5頁',
        '自由編輯修改',
        '子網域',
      ],
    },
    {
      title: '尊爵方案',
      price: '10,999',
      originalPrice: '21,998',
      period: '/year',
      description: '提升曝光機會，有效抓住客群',
      tooltipContent: (
        <ul>
          <li>方案僅限年繳</li>
          <li>另需收取一次性系統開通設定費NT$5,000</li>
          <li>每月收取定額系統維護費5%(依專案費用5%計收)</li>
        </ul>
      ),
      features: [
        '套版三選一',
        '專屬網域',
        '頁面數量5頁',
        '商品上架介紹',
        '商品上架數量12個',
      ],
    },
    {
      title: '客製化方案',
      price: '8,999',
     
      price: '議定',
      period: '',
      description: '專為新創企業而生的網路形象代表，提供快速佈建及資訊瀏覽可與世界接軌，走向國際化',
      tooltipContent: '請聯絡我們',
      features: [
        '具備客製化及質感的介面設計',
        '專屬網域',
        '頁面數量依據客戶需求而定',
        '是否需商品上架頁及上架數量',
        '系統建置專屬服務',
        '線上系統顧問諮詢',
      ],
    },
  ],
};



const pricingDataTwo = {
  monthly: [
    {
      title: '⍬方案',
      price: '$2,500', 
      annualPrice: '29,999',
      description: '提供您輕量計費，讓您創業無負擔',
      period: '/month',
      tooltipContent: (
        <ul>
          <li>方案僅限年繳</li>
          <li>另需收取一次性系統開通設定費NT$5,000</li>
          <li>每月收取定額系統維護費5%(依專案費用5%計收)</li>
        </ul>
      ),
      features: [
        '訂單管理',
        '會員管理',
        '頁面數量10頁',
        '商品管理（商品數量100項）',
        '金物流串接',
        '文章部落格發布',
      ],
    },
    {
      title: '⍺方案',
      price: '$4,167', 
      annualPrice: '49,999',
      description: '提供您省時省力，管理最適工具',
      period: '/month',
      tooltipContent: (
        <ul>
          <li>方案僅限年繳</li>
          <li>另需收取一次性系統開通設定費NT$5,000</li>
          <li>每月收取定額系統維護費5%(依專案費用5%計收)</li>
        </ul>
      ),
      features: [
        '網站支援多語系',
        '美安行銷整合串接',
        '多樣化套版選擇',
        '紅利管理系統',
        '領券中心',
      ],
    },
    {
      title: 'µ方案',
      price: '$5,833', 
      annualPrice: '69,999',
      description: '提供您軟體網站，雲端服務最佳解',
      period: '/month',
      tooltipContent: (
        <ul>
          <li>方案僅限年繳</li>
          <li>另需收取一次性系統開通設定費NT$5,000</li>
          <li>每月收取定額系統維護費5%(依專案費用5%計收)</li>
        </ul>
      ),
      features: [
        '進階通知設定',
        '第三方社群平台串接',
        '促銷與分潤系統',
        '會員分級行銷',
        'Big-O Builder',
      ],
    },
    {
      title: 'Ω方案',
      price: '$6,667', 
      annualPrice: '79,999',
      description: '提供您全方位線上銷售利器',
      period: '/month',
      tooltipContent: (
        <ul>
          <li>方案僅限年繳</li>
          <li>另需收取一次性系統開通設定費NT$5,000</li>
          <li>每月收取定額系統維護費5%(依專案費用5%計收)</li>
        </ul>
      ),
      features: [
        '稽核權限管理',
        '綜合報表管理',
        '定期購',
        '預約管理',
        '客服管理',
      ],
    },
  ],
  annual: [
    {
      title: '⍬方案',
      price: '$29,999', 
      originalPrice: '45,999',
      description: '提供您輕量計費，讓您創業無負擔',
      period: '/month',
      tooltipContent: (
        <ul>
          <li>方案僅限年繳</li>
          <li>另需收取一次性系統開通設定費NT$5,000</li>
          <li>每月收取定額系統維護費5%(依專案費用5%計收)</li>
        </ul>
      ),
      features: [
        '訂單管理',
        '會員管理',
        '頁面數量10頁',
        '商品管理（商品數量100項）',
        '金物流串接',
        '文章部落格發布',
      ],
    },
    {
      title: '⍺方案',
      price: '$49,999', 
      originalPrice: '116,999',
      description: '提供您省時省力，管理最適工具',
      period: '/month',
      tooltipContent: (
        <ul>
          <li>方案僅限年繳</li>
          <li>另需收取一次性系統開通設定費NT$5,000</li>
          <li>每月收取定額系統維護費5%(依專案費用5%計收)</li>
        </ul>
      ),
      features: [
        '網站支援多語系',
        '美安行銷整合串接',
        '多樣化套版選擇',
        '紅利管理系統',
        '領券中心',
      ],
    },
    {
      title: 'µ方案',
      price: '$69,999', 
      originalPrice: '145,999',
      description: '提供您軟體網站，雲端服務最佳解',
      period: '/month',
      tooltipContent: (
        <ul>
          <li>方案僅限年繳</li>
          <li>另需收取一次性系統開通設定費NT$5,000</li>
          <li>每月收取定額系統維護費5%(依專案費用5%計收)</li>
        </ul>
      ),
      features: [
        '進階通知設定',
        '第三方社群平台串接',
        '促銷與分潤系統',
        '會員分級行銷',
        'Big-O Builder',
      ],
    },
    {
      title: 'Ω方案',
      price: '$79,999', 
      originalPrice: '228,999',
      description: '提供您全方位線上銷售利器',
      period: '/month',
      tooltipContent: (
        <ul>
          <li>方案僅限年繳</li>
          <li>另需收取一次性系統開通設定費NT$5,000</li>
          <li>每月收取定額系統維護費5%(依專案費用5%計收)</li>
        </ul>
      ),
      features: [
        '稽核權限管理',
        '綜合報表管理',
        '定期購',
        '預約管理',
        '客服管理',
      ],
    },
  ],
};

const Pricing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <section className="pricing-section">
      <div>
        <BannerSection
        category="方案費用"
        title="無牆開店，超乎你我想像"
        subtitle='我們提供形象網站及購物平台的不同方案，讓您有更多選擇，不知道自己適合哪一種方案嗎？請<a href="/contact">聯繫我們</a>'
        backgroundImage={PricingBanner}
      />
      </div>
      <div className="">
        <div className="text-center mb-4">
          <ul className="nav nav-pills justify-content-center" id="pricing-tabs" role="tablist">
            <li className="nav-item">
              <a className="nav-link active" id="monthly-tab" data-bs-toggle="pill" href="#monthly" role="tab">形象網站</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" id="annual-tab" data-bs-toggle="pill" href="#annual" role="tab">購物網站</a>
            </li>
          </ul>
        </div>

        <div className="tab-content">
          <div className="tab-pane fade show active" id="monthly" role="tabpanel">
          <div className="container">
            <div className="row">
            <PricingSwitcher pricingData={pricingData} />
              </div>
            </div>
          </div>
          <div className="tab-pane fade" id="annual" role="tabpanel">
          <div className="container">
          <div className="row">
                <PricingSwitcherTwo pricingData={pricingDataTwo} /> {/* Corrected the reference */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
