import React, { useEffect } from 'react';
import '../styles/ProductUpdates.css'; 

import BannerSectionDark from '../components/BannerSectionDark';
import BannerImage from '../assets/productupdates-banner.png'; 
import ArticleImage from '../assets/article-image.png';

const ProductUpdates = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const articles = [
    { 
      date: '2024-04-12',  
      image: ArticleImage, 
      title: '2024/04產品更新現況：無牆開店e秒通產品新增會員註冊可採用第三方註冊', 
      description: 'Big-O 無牆開店e秒通持續增加更多元的註冊方式，為便利更多客戶使用我們的系統，目前該系統新增以下功能：', 
      link: '/article-two' 
    },
  
  ];

  return (
    <div className="ProductUpdates">
      <div>
        <BannerSectionDark 
          category="產品更新紀錄"
          title="產品更新紀錄"
          subtitle="快速了解各產品更新最新現況"
          backgroundImage={BannerImage}
        />
      </div>
      <section className="articles-section">
        <div className="container">
          {articles.map((article, index) => {
            const [year, month, day] = article.date.split('-'); 
            return (
              <div className="article-item d-flex pt-5 pb-4" key={index}>
                <div className="article-date text-center">
                  <div className="date-day">{day}</div>
                  <div className="date-month">{new Date(article.date).toLocaleString('default', { month: 'short' }).toUpperCase()}</div>
                </div>
                <div className="article-image">
                  <img src={article.image} alt={article.title} className="img-fluid" />
                </div>
                <div className="article-content pl-4">
                  <h3>{article.title}</h3>
                  <p>{article.description}</p>
                  <a href={article.link} className="btn btn-link">了解更多 →</a>
                </div>
              </div>
            );
          })}
        </div>
      </section>

      {/* <div className="pagination-section text-center py-4">
        <nav>
          <ul className="pagination justify-content-center">
            <li className="page-item disabled">
              <a className="page-link" href="#">
                <i className="fas fa-chevron-left"></i>
              </a>
            </li>
            <li className="page-item"><a className="page-link" href="#">1</a></li>
            <li className="page-item"><a className="page-link" href="#">2</a></li>
            <li className="page-item"><a className="page-link" href="#">3</a></li>
            <li className="page-item"><a className="page-link" href="#">4</a></li>
            <li className="page-item"><a className="page-link" href="#">5</a></li>
            <li className="page-item"><a className="page-link" href="#">...</a></li>
            <li className="page-item"><a className="page-link" href="#">10</a></li>
            <li className="page-item">
              <a className="page-link" href="#">
                <i className="fas fa-chevron-right"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div> */}
    </div>
  );
};

export default ProductUpdates;
