import React, { useEffect } from 'react';
import BrandPartners from '../components/BrandPartners';
import BannerSection from '../components/BannerSection';
import AboutUsBanner from '../assets/AboutUsBanner.png'; 
import AboutUsImage from '../assets/aboutus/AboutUs-01.png'; 
import AboutUsImage02 from '../assets/aboutus/AboutUs-02.png'; 
import AboutUsImage03 from '../assets/aboutus/AboutUs-03.png'; 
import Timeline from '../components/Timeline';
import '../styles/AboutUs.css'; 
import CardRow from '../components/CardRow'; 

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="AboutUs">
      <div>
        <BannerSection 
          category="關於我們"
          title="Big-O落實在地應用"
          subtitle="Big-O提供網路開店創業夢想，也提供教育、金融、醫療及建築與設計產業之落地應用。"
          backgroundImage={AboutUsBanner}
        />
      </div>
      <div className="py-4">
        <div className="container pt-5">
          <div className="row align-items-center">
            <div className="col-md-4 col-12 mb-4 mb-md-0">
              <h3>
                <span className="gold">Big-O</span> 的故事
              </h3>
              <p>
                Big-O於2023年成立，以提供台灣中小微型企業數位轉型，與邁向網路世界及雲端發展之解決方案為目標，期盼協助各產業界有更多的應用及落實在地化，並協助企業主對於自我公司數位轉型上之評估與尋找問題的痛點。公司採用雲端協作方式開啟小規模創業之路，期許協助導入更多AI新技術，達成真正落地應用與造福回饋台灣社會為宗旨，希冀本公司能成為台灣AI科技島上的重要角色，以及成為資服軟體業提供最佳解決方案的標竿。
              </p>
            </div>
            <div className="col-md-4 col-12 mb-4 mb-md-0">
              <div className="d-flex justify-content-center">
                <img src={AboutUsImage} alt="Team Working" className="img-fluid"/>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="mt-4">
                <div className="mb-3">
                  <span className="h4">30+</span>雲端網路創業
                  <p className="mb-0">
                   Big-O提供完整且便捷的操作系統，一站式服務，滿足您網路創業所有需求（資金取得、創業輔導、、等）。

                  </p>
                </div>
                <div className="mb-3">
                  <span className="h4">90%</span>雲端開發協作團隊
                  <p className="mb-0">
                   Big-O隨時提供完整系統操作問題之解決方案，並彈性協作自由無限。

                  </p>
                </div>
                <div className="mb-3">
                  <span className="h4">5🌟</span>雲端服務滿意度
                  <p className="mb-0">
                   Big-O提供完善的解決方案，客戶滿意度近五星好評。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BrandPartners title="優質品牌合作夥伴" />
     
      <div className="custom-solution-section py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="solution-graphic">
                <img src={AboutUsImage02} alt="AI and Cloud Solutions" className="img-fluid"/>
              </div>
              <p className="solution-label">註：UNFRANCHISE ONLINE STORE (UOS)/ MEDICAL SERVICE (MS) / EDUCATION SELF-MEDIA (ESM) / BUILDING and DESIGN (BD)/ FINANCIAL SERVICE(FS)</p>
            </div>
            <div className="col-md-6">
              <h2>雲端客製服務，智慧驅動成長</h2>
              <p className="mb-4">
              Big-O是軟體即服務(SaaS)公司，結合新興科技(ET)與人工智慧(AI)技術推動數位轉型。協助公司了解客戶、增加客源以達成更完善的業務成效，亦提供流程再造、人工智慧技術、數位系統建置、網路行銷與企劃、策略規劃之相關服務。
              </p>
              <a href="/micro-business-solutions-feature" className="custom-button-bottom">了解核心產品 →</a>
            </div>
          </div>
        </div>
      </div>
      <Timeline/>
      <div className="esg-section bg-gray py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 mb-5">
              <h2>幫助微型企業茁壯，實踐ESG永續經營</h2>
              <p className="mb-4">
              Big-O戮力於幫助微型企業成長的向上力量，並積極配合政府專案，深耕中小微型企業在地化，創造數位智能發展潛力。藉由本公司三軸驅動量能，協助新創成長與發展，提供五大產業解決方案，積極投入綠能與永續發展。 
              </p>
              <a href="/resources" className="custom-button-bottom">前往白皮書及指南 →</a>
            </div>
            <div className="col-md-6">
              <div className="image-container">
                <img src={AboutUsImage03} alt="ESG in Action" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container pt-5">
          <div className="text-center pb-4">
            <h2 className="talk-title">Let’s Talk With Big-O !!</h2>
          </div>
          <CardRow />
        </div>
      </div>
    </section> 
  );
};

export default AboutUs;

