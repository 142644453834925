import React, { useEffect } from 'react';
import '../styles/Contact.css';
import BannerSection from '../components/BannerSection';
import bannerImage from '../assets/Contact-banner.png';
import icon1 from '../assets/contact/Icon.svg';  
import icon2 from '../assets/contact/Icon2.svg'; 
import icon3 from '../assets/contact/Icon3.svg';  
import icon4 from '../assets/contact/Icon4.svg'; 
import emailIcon from '../assets/contact/email.svg';
import phoneIcon from '../assets/contact/phone.svg';
import messageplussquare from '../assets/contact/message-plus-square.svg';

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const cardPairs = [
    [
      {
        icon: icon1,
        title: '了解Big-O方案',
        description: '想取得Big-O的解決方案嗎？了解如何透過雲端及AI技術完成您的數位轉型。',
        email: 'service@boiic.org',
        phone: '02-8979-1656',
        justify: 'end'
      },
      {
        icon: icon2,
        title: '聯絡Big-O客服',
        description: '詢問方案，及系統佈建等整體建置與操作問題。',
        email: 'service@boiic.org',
        phone: '02-8979-1656',
        justify: 'start'
      }
    ],
    [
      {
        icon: icon3,
        title: '洽詢Big-O公關',
        description: '新聞訊息、媒體採訪、企業相關合作及其他公共關係事宜。',
        email: 'service@boiic.org',
        phone: '02-8979-1656',
        justify: 'end'
      },
      {
        icon: icon4,
        title: '加入Big-O團隊',
        description: '如果您是想要加入AI與Cloud科技領域發展，致力用最高效能完成最佳解，歡迎加入我們的Big-O Top團隊，One team, One Dream!!',
        isLink: true,
        link: 'https://www.104.com.tw/company/1a2x6bmsw1?jobsource=index_s_ac',
      }
    ]
  ];

  return (
    <section className="contact">
      <div>
        <BannerSection 
          category="很高興能為您服務"
          title="歡迎與Big-O聯繫！"
          subtitle="與我們一同發揮高效能，解決您的數位需求！"
          backgroundImage={bannerImage}
        />
      </div>
      <div className='col-lg-12'>
        <div className='row'>
        <div className="service-hours">
          <p>服務時間：週一至週五</p>
          <p>上午09:30~12:30 | 下午13:30~17:30</p>
        </div>

        <div className="contact-form-section">
          <h2>請留下聯絡方式，我們將盡快與你聯繫！</h2>
          <form className="contact-form">
            <input type="text" placeholder="您的名字" required />
            <input type="tel" placeholder="電話" required />
            <input type="email" placeholder="電子信箱" required />
            <button type="submit" className="submit-button">立即諮詢</button>
          </form>
        </div>
        </div>
      </div>
    
      <div className="container">
        {cardPairs.map((pair, index) => (
          <div className="row justify-content-top g-4 mb-4" key={index}>
            {pair.map((card, i) => (
              <div className={`col-md-6 d-flex justify-content-${card.justify}`} key={i}>
                <div className="card card-square">
                  <div className="card-body d-flex align-items-start">
                    <div className='d-flex align-items-center'>
                      <div className="icon-container me-3">
                        <img src={card.icon} alt={card.title} className="img-fluid"  />
                      </div>
                      <h5 className="card-title">{card.title}</h5>
                    </div>
                    <div className="text-container">
                      <p className="card-text mt-3">{card.description}</p>
                      
                      {card.phone && (
                        <p className="card-text">
                          <small className="text-muted">
                            <img src={phoneIcon} alt="Phone Icon" className="icon-svg me-2" />
                            {card.phone}
                          </small>
                        </p>
                      )}
                      
                      {card.email && (
                        <p className="card-text">
                          <small className="text-muted">
                            <img src={emailIcon} alt="Email Icon" className="icon-svg me-2" />
                            <a href={`mailto:${card.email}`}>{card.email}</a>
                          </small>
                        </p>
                      )}
    
                     

                      {card.isLink && (
                        <a href={card.link} className="card-link d-flex align-items-center">
                          <img src={messageplussquare} alt="Link Icon" className="icon-svg me-2" />
                          查看Big-O工作機會
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Contact;
