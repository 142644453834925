import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import banner01 from '../assets/banner/banner01.jpg'; 
import banner02 from '../assets/banner/banner02.jpg'; 
import banner03 from '../assets/banner/banner03.jpg'; 

const MainSlider = () => {
  return (
    <section className="full-width">
      <div id="heroCarousel" className="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
        <ul className="carousel-indicators">
          <li data-bs-target="#heroCarousel" data-bs-slide-to="0" className="active"></li>
          <li data-bs-target="#heroCarousel" data-bs-slide-to="1"></li>
          <li data-bs-target="#heroCarousel" data-bs-slide-to="2"></li>
        </ul>

        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={banner01} className="d-block w-100" alt="Slide 1" />
          </div>
          <div className="carousel-item">
          <img src={banner02} className="d-block w-100" alt="Slide 1" />
          </div>
          <div className="carousel-item">
          <img src={banner03} className="d-block w-100" alt="Slide 1" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSlider;
