import React, { useState, useEffect } from 'react';
import '../styles/FAQ.css'; 
import BannerSectionDark from '../components/BannerSectionDark';
import BannerImage from '../assets/faq-banner.png'; 
import ArrowDownIcon from '../assets/faq/arrow-down.svg'; 
import ArrowUpIcon from '../assets/faq/arrow-up.svg';

const FAQ = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeTab, setActiveTab] = useState("微型商務解決方案");

  const faqData = {
    "微型商務解決方案": [
      {
        question: "Big-O有哪些開店服務內容？",
        answer: `Big-O提供無牆開店e秒通解決方案，並提供多樣化套版，可依據您的公司規模、營銷管道及需求等，選擇最適合您網路生意的解決方案。如另有網站客製化需求，歡迎聯繫Big-O，我們將儘速為您解答。<a href="/contact">聯絡我們</a>`
      },
      {
        question: "Big-O的無牆開店e秒通方案服務有哪些功能特色？",
        answer: `Big-O了解微型商務創業主的需要，全力打造簡易操作及功能強大的智能開店平台服務，系統頁面設計簡單明瞭，具備便捷的操作功能，並提供相關教學文件，協助中小微企業可快速上手。以直覺式的管理後台，輕輕鬆鬆即可上架商品，建立屬於您的專屬電商網站。客戶可從選購至加入購物車到結單一站式購足，提高客戶於數位購物之體驗。對於中小微企業主，亦提供google analytics數據分析功能，了解自身商品之特性與客戶偏好，並提供SEO搜尋引擎之關鍵字設定，透過提升搜尋引擎排名，強化廣告效益與精準行銷。`
      },
      {
        question: "Big-O提供之無牆開店e秒通解決方案有哪些金流服務？",
        answer: `Big-O提供多樣化付款機制，包含統一金流、綠界金流、Line Pay等多元選項，收付款之便利，讓您輕鬆收款並拓展業務。`
      },
      {
        question: "Big-O有提供形象網站嗎？什麼是RWD設計？有什麼好處？",
        answer: `Big-O在無牆開店e秒通核心產品項下，有提供企業客戶需求的純形象網站方案。RWD稱為「響應式網頁設計」，是針對網站不同尺寸來設計，適用於手機、平板、電腦網頁等，可以提供瀏覽者更舒適的感受，Big-O提供RWD網站可讓用戶隨手轉貼網址到行動裝置上，立即購買商品，迅速傳遞最新正確訊息給您的客戶，才能把握任何一個機會，所以提供響應式網頁是標準配置。`
      },
      {
        question: "請問網站是否需具備SSL功能？",
        answer: `SSL是安全通訊協定的簡稱，現在可將視線移到瀏覽器的左上角查看，在輸入網址框的旁邊可以看到一個「保護網站安全的小鎖頭」，這是網站有標準安裝配備SSL的標示，網站在資訊傳輸的時候，為了防止被竊取或是被竄改項目內容，我們必須透過特殊的加密演算技術，將資料加密之後再做傳送，比如說個人訊息、地址、信用卡等資訊，保護網站也保護在你網站上使用留下記錄的人。所以，Big-O提供的網站建置服務都安裝SSL以確保網站的安全性。`
      },
      {
        question: "請問是否需要專屬網域(Domain)？有何差異？",
        answer: `網域就像您企業在網路上的商標品牌，能讓客戶透過「網域」來精準找到您的產品，若是沒有屬於自己的品牌專屬域名，影響的就是您的網站網址及電子郵件地址。Big-O所提供的網站建置服務方案有專屬網域也有採用子網域，可依據您的實際需求及成本考量，選擇適合您的解決方案。`
      }
    ],
    "教育自媒體解決方案": [],
    "整合式醫療服務解決方案": [],
    "智慧建築與設計應用解決方案": [],
    "金融實戰應用解決方案": []
  };

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="faq">
      <div>
        <BannerSectionDark 
          category="核心產品Q&A"
          title="核心產品Q&A"
          subtitle="有疑問嗎？帶您了解核心產品大小事"
          backgroundImage={BannerImage}
        />
      </div>
      <div className="container py-5">

        <div className="faq-tabs">
          {Object.keys(faqData).map((tab) => (
            <button
              key={tab}
              className={`faq-tab ${activeTab === tab ? 'active' : ''}`}
              onClick={() => {
                setActiveTab(tab);
                setActiveIndex(null); 
              }}
            >
              {tab}
            </button>
          ))}
        </div>

        {faqData[activeTab].length > 0 ? (
          faqData[activeTab].map((item, index) => (
            <div key={index} className="faq-item">
              <div className="faq-question" onClick={() => toggleAnswer(index)}>
                {item.question}
                <span className={`faq-icon ${activeIndex === index ? 'open' : ''}`}>
                  {activeIndex === index ? (
                    <img src={ArrowUpIcon} alt="Collapse" />
                  ) : (
                    <img src={ArrowDownIcon} alt="Expand" />
                  )}
                </span>
              </div>
              <div className={`faq-answer ${activeIndex === index ? 'show' : ''}`}>
                <div dangerouslySetInnerHTML={{ __html: item.answer }} />
              </div>
            </div>
          ))
        ) : (
          <p>此類別暫無常見問題。</p>
        )}
      </div>
    </section>
  );
};

export default FAQ;
