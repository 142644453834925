import React, { useState, useEffect } from 'react';
import '../styles/MicroBusinessSolutionsStore.css';
import BannerSection from '../components/BannerSection';
import PricingBanner from '../assets/buyyourself-banner.png';
import StoreImage from '../assets/storeImage.png';
import featureIcon from '../assets/icon/list.svg'; 

const MicroBusinessSolutionsStore = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const [activeTab, setActiveTab] = useState('store-setup');

  const renderContent = () => {
    switch (activeTab) {
      case 'store-setup':
        return (
          <div className="tab-pane fade show active">
            <h3>商店建立</h3>
            <p>持續優化系統效能及安全防護，為了你的成長而做的萬全準備。</p>
            <div className="row mt-4">
              <div className="col-md-12">
                <img src={StoreImage} alt="Store Setup" className="img-fluid mb-3" />
              </div>
            </div>
            <div className="row feature-list">
              <div className="feature-item">
              <img src={featureIcon} className="img-fluid mb-3" />
                <div>
                  <h5>色塊化區分訂單類別</h5>
                  <p>架設購物網站沒想像中的難！透過後台的拖曳方式就能完成網店建立，並擁有購物車、多元金物流及加入會員等多種功能，為你的網路生意做好一切準備。</p>
                </div>
              </div>
              <div className="feature-item">
              <img src={featureIcon} className="img-fluid mb-3" />
                <div>
                  <h5>未完成購物車結帳提醒</h5>
                  <p>架設購物網站沒想像中的難！透過後台的拖曳方式就能完成網店建立，並擁有購物車、多元金物流及加入會員等多種功能，為你的網路生意做好一切準備。</p>
                </div>
              </div>
              <div className="feature-item">
              <img src={featureIcon} className="img-fluid mb-3" />
                <div>
                  <h5>色塊化區分訂單類別</h5>
                  <p>架設購物網站沒想像中的難！透過後台的拖曳方式就能完成網店建立，並擁有購物車、多元金物流及加入會員等多種功能，為你的網路生意做好一切準備。</p>
                </div>
              </div>
              <div className="feature-item">
              <img src={featureIcon} className="img-fluid mb-3" />
                <div>
                  <h5>未完成購物車結帳提醒</h5>
                  <p>架設購物網站沒想像中的難！透過後台的拖曳方式就能完成網店建立，並擁有購物車、多元金物流及加入會員等多種功能，為你的網路生意做好一切準備。</p>
                </div>
              </div>
            </div>
          </div>
        );
      case 'inventory-management':
        return (
          <div className="tab-pane fade show active">
            <h3>商品管理</h3>
            <p>商品管理的相關內容。</p>
          </div>
        );
      case 'logistics-connection':
        return (
          <div className="tab-pane fade show active">
            <h3>金物流串接</h3>
            <p>金物流串接的相關內容。</p>
          </div>
        );
      case 'regulations':
        return (
          <div className="tab-pane fade show active">
            <h3>訂單管理</h3>
            <p>訂單管理的相關內容。</p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <section className="MicroBusinessSolutionsStore">
      <div>
        <BannerSection
          category="自主開店"
          title="業界首創-彈性選購，客製化需求"
          subtitle='自行採買結帳，滿足您雲端網店所需！請<a href="/contact">聯繫我們</a>'
          backgroundImage={PricingBanner}
        />
      </div>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-3">
            <div className="nav flex-column nav-pills">
              <button
                className={`nav-link ${activeTab === 'store-setup' ? 'active' : ''}`}
                onClick={() => setActiveTab('store-setup')}
              >
                商店建立
              </button>
              <button
                className={`nav-link ${activeTab === 'inventory-management' ? 'active' : ''}`}
                onClick={() => setActiveTab('inventory-management')}
              >
                商品管理
              </button>
              <button
                className={`nav-link ${activeTab === 'logistics-connection' ? 'active' : ''}`}
                onClick={() => setActiveTab('logistics-connection')}
              >
                金物流串接
              </button>
              <button
                className={`nav-link ${activeTab === 'regulations' ? 'active' : ''}`}
                onClick={() => setActiveTab('regulations')}
              >
                訂單管理
              </button>
            </div>
          </div>
          <div className="col-md-9">
            {renderContent()}
          </div>
        </div>
      </div>
    </section>
  );
};

export default MicroBusinessSolutionsStore;
