import React from 'react';
import '../styles/BannerSection.css'; 

const BannerSection = ({ category, title, subtitle, backgroundImage }) => {
  return (
    <section 
      className="banner-section" 
      style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="container">
        <h5 className="banner-category">{category}</h5>
        <h1 className="banner-title">{title}</h1>
        <p className="banner-subtitle" dangerouslySetInnerHTML={{ __html: subtitle }}></p>
      </div>
    </section>
  );
};

export default BannerSection;
