import React from 'react';
import { Carousel } from 'react-bootstrap';
import Group60 from '../assets/Group60.png'; 
import '../styles/LeftSliderSection.css'; 

const solutions = [
  {
    number: "01",
    title: "Micro Commerce Solution",
    subtitle: "微型商務解決方案",
    features: [
      "多樣化設計主題",
      "超強訂單管理平台",
      "完整金物流串接",
      "品牌深化會員經營",
      "便捷流暢的購物體驗",
    ],
    link: "/micro-business-solutions-feature"
  },
  {
    number: "02",
    title: "Education Media Solutions",
    subtitle: "教育自媒體解決方案",
    features: [
      "課前提醒課後追蹤",
      "一頁式瀏覽清晰明暸",
      "行動分銷",
      "完善金流機制",
      "網紅式行銷",
    ]
  },
  {
    number: "03",
    title: "Integrated Medical Service Solutions",
    subtitle: "整合式醫療服務解決方案",
    features: [
      "解決診所擁塞問題",
      "提供便捷的藥物衛教",
      "減少醫生問診時間",
      "打造智能醫病訪談室",
      "智能長照預約及雲端看診",
    ]
  },
  {
    number: "04",
    title: "Smart Building and Design Solutions",
    subtitle: "智慧建築與設計應用解決方案",
    features: [
      "綠色建築減碳",
      "社區APP智慧生活管理系統",
      "AI智能建築設計",
      "智慧建築營造管理雲平台",
      "AI智能室內設計",
    ]
  },
  {
    number: "05",
    title: "Financial Application Solutions",
    subtitle: "金融實戰應用解決方案",
    features: [
      "智能合約加速信用狀開狀系統",
      "放款AI智慧審案系統",
      "中小微型企業智慧撥貸系統",
      "智慧家庭金融便利通",
      "智慧辦公小助手",
    ]
  },
];

const LeftSliderSection = () => {
  return (
    <section className="LeftSliderSection py-5 bg-gray">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-lg-6 order-2 order-md-1">
            <div className="image-section text-center">
              <img src={Group60} alt="Device Showcase" className="img-fluid" />
            </div>
          </div>
          <div className="col-12 col-lg-6 order-1 order-md-2">
            <div className="slider-section mt-4">
              <Carousel>
                {solutions.map((solution, index) => (
                  <Carousel.Item key={index}>
                    <div className="solution-section pt-5">
                      <h2 className="section-number">{solution.number}</h2>
                      <div className="section-content">
                        <h3 className="section-title">{solution.title}</h3>
                        <p className="section-subtitle">{solution.subtitle}</p>
                      </div>
                    </div>
                    <ul className="solution-list">
                      {solution.features.map((feature, i) => (
                        <li key={i}><i className="icon-link"></i> {feature}</li>
                      ))}
                    </ul>
                    <a href={solution.link} className="learn-more-button ml-4 mt-4">了解更多</a>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LeftSliderSection;
