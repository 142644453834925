import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import CallToActionTwo from '../components/CallToActionTwo';
import '../styles/Partnership.css'; 
import BannerSection from '../components/BannerSection';
import BannerImage from '../assets/PartnershipBanner.png'; 
import PayUniLogo from '../assets/payuni.png'; 
import CatLogo from '../assets/catlogo.png'; 
import GwLogo from '../assets/gw_ssl-1.png'; 
import PartnershipBannerIcon from '../assets/PartnershipBannerIcon1.png';

const Partnership = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="Partnership">
      <Helmet>
        <title>策略聯盟 - 尋找技術合作夥伴 | 企業成長與創新</title>
        <meta name="description" content="透過與策略合作夥伴協作，快速獲得創新及智能服務，實現企業業績增長與服務便捷化。" />
        <meta name="keywords" content="策略聯盟, 技術合作夥伴, 企業成長, 創新服務, 電子支付, 物流服務" />
      </Helmet>
      
      <div>
        <BannerSection 
          category="策略聯盟"
          title="尋找技術合作夥伴"
          subtitle="透過與協作之技術合作夥伴，可快速獲得創新及智能服務。"
          backgroundImage={BannerImage}
        />
      </div>
      
      <section className="py-5 bg-gray">
        <div className="container text-center">
          <h2>跟技術夥伴合作的三大優勢</h2>
          <div className="row mt-4">
            <div className="col-md-4 col-sm-12 mb-4">
              <div className="advantage-item">
                <img src={PartnershipBannerIcon} alt="整合業務" className="advantage-icon img-fluid mb-3" />
                <h5 className="advantage-title">整合業務</h5>
                <p>透過與技術合作夥伴協作，帶來更多客群與服務機會，各式業務整合一體。</p>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 mb-4">
              <div className="advantage-item">
                <img src={PartnershipBannerIcon} alt="先進的技術創新" className="advantage-icon img-fluid mb-3" />
                <h5 className="advantage-title">先進的技術創新</h5>
                <p>具備與Big-O產品進行整合，可獲得最新技術與創新概念，落實最新智能服務應用。</p>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 mb-4">
              <div className="advantage-item">
                <img src={PartnershipBannerIcon} alt="加強在地服務便利性" className="advantage-icon img-fluid mb-3" />
                <h5 className="advantage-title">加強在地服務便利性</h5>
                <p>提供客製與彈性兼具的產品及服務，強化客戶使用便利性，大幅提升服務效益。</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container text-center">
          <h2>立即尋找合作夥伴</h2>
          <div className="row mt-4">
            <div className="col-md-4 col-sm-12 mb-4">
              <div className="partner-item">
                <div className="d-flex flex-column align-items-center">
                  <img 
                    src={GwLogo} 
                    alt="綠界金流" 
                    className="img-fluid mb-3  w-75" 
                    style={{ height: '150px', objectFit: 'contain' }} 
                  />
                  <p>綠界科技（EC Pay）是台灣的支付服務提供商，專注於提供多元化的電子支付解決方案，支持信用卡、ATM 轉帳、超商付款等方式，致力於為用戶提供安全便捷的支付體驗。</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 mb-4">
              <div className="partner-item">
                <div className="d-flex flex-column align-items-center">
                  <img 
                    src={PayUniLogo} 
                    alt="統一金流" 
                    className="img-fluid mb-3  w-75" 
                    style={{ height: '150px', objectFit: 'contain' }} 
                  />
                  <p>PayUni 是一個提供多元化電子支付服務的平台，旨在為用戶提供便捷、安全的支付方式，支持多種支付選項，適用於各類線上購物與商業交易。</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 mb-4">
              <div className="partner-item">
                <div className="d-flex flex-column align-items-center">
                  <img 
                    src={CatLogo} 
                    alt="黑貓宅急便" 
                    className="img-fluid mb-3  w-75" 
                    style={{ height: '150px', objectFit: 'contain' }} 
                  />
                  <p>宅急便（黑貓宅急便）是日本一家知名的物流服務公司，專注於提供快遞和包裹運輸服務，以其快捷、可靠的配送而聞名。</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <CallToActionTwo 
        title="立即啟用技術夥伴服務"
        subtitle="根據您的需求以及洽詢之解決方案，從技術合作列表尋找適合您的服務。"
        buttonText="尋找合作夥伴"
        buttonLink="/contact"
      />

    </div>
  );
};

export default Partnership;
