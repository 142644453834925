import React, { useEffect } from 'react';
import MainSlider from '../components/MainSlider';
import SecondMainSection from '../components/SecondMainSection';
import LeftSliderSection from '../components/LeftSliderSection';
import BrandServices from '../components/BrandServices';
import LatestCasesT1 from '../components/LatestCasesT1';
import BrandPartners from '../components/BrandPartners';
import CallToAction from '../components/CallToAction';
import '../styles/style.css';

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <MainSlider />
      <SecondMainSection />
      <LeftSliderSection />
      <BrandServices />
      <LatestCasesT1 />
      <BrandPartners />
      <CallToAction />
    </div>
  );
};

export default Home;
